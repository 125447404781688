@keyframes slideInFromLeft {
    0% {
      opacity: 0;
      
    }
   
    100% {
        opacity: 1;
    }
  }
  

.pozisyonlarGrid {
    animation: 1s ease-in-out slideInFromLeft;
}